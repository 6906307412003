import styles from "./main.module.scss"
import { Outlet } from "react-router-dom"
import { Appbar } from "./appbar"

export const Main = () => (
  <div className={styles.container}>
    <Appbar />
    <Outlet />
  </div>
)
