import "./alerting"

// import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { AuthProvider, logAnalytics } from "./auth"
import { ApiProvider } from "./api"
import { Router } from "./router"
// import "@fontsource/roboto/300.css"
// import "@fontsource/roboto/400.css"
// import "@fontsource/roboto/500.css"
// import "@fontsource/roboto/700.css"
import "./styles/index.scss"

logAnalytics("page_view")

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <StrictMode>
  <AuthProvider>
    <ApiProvider>
      <Router />
    </ApiProvider>
  </AuthProvider>
  // </StrictMode>
)
