import * as Sentry from "@sentry/browser"
import { config } from "./config"

const { env, isDev, sentry_dsn } = config()

Sentry.init({
  dsn: sentry_dsn,
  enabled: !isDev,
  environment: env,
  release: new Date().toDateString(),
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions
  // for tracing. We recommend adjusting this value in production:
  tracesSampleRate: 1.0,
  sampleRate: 1,
  // Set `tracePropagationTargets` to control for
  // which URLs trace propagation should be enabled:
  // tracePropagationTargets: [],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
})
