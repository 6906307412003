import { config } from "../config"
import { getAnalytics, logEvent } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth"

const { firebase } = config()
const firebaseApp = initializeApp(firebase)
const analytics = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)

export const memberAuth = {
  current() {
    return auth.currentUser
  },
  token() {
    return memberAuth.current()?.getIdToken()
  },
  create(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password)
  },
  login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
  },
  logout() {
    return signOut(auth)
  },
  password: {
    reset(email: string) {
      return sendPasswordResetEmail(auth, email)
    },
    update(newPassword: string) {
      return updatePassword(auth.currentUser!, newPassword)
    },
  },
  loginGmail() {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: "select_account ",
    })
    return signInWithPopup(auth, provider)
  },
}

export function logAnalytics(event: string, memberId?: number) {
  if (process.env.NODE_ENV === "development") {
    return
  }
  logEvent(analytics, event, {
    page_path: location.pathname,
    memberId,
  })
}

// @ts-ignore
window.__member = memberAuth
