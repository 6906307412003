import { useState } from "react"
import { memberAuth } from "../auth"
import { useNavigate } from "react-router-dom"
import { AuthFormWrapper } from "../shared/auth-form-wrapper"
import { ErrorAlert } from "../shared/alerts"
import { GoogleG } from "../shared/googleg"

export const Signup: React.FC = () => {
  const navigate = useNavigate()

  const [Email, SetEmail] = useState("")
  const [Password, SetPassword] = useState("")
  const [PasswordConfirmation, SetPasswordConfirmation] = useState("")
  const [ErrorMsg, SetErrorMsg] = useState("")
  const [Loading, SetLoading] = useState(false)

  async function signup() {
    SetErrorMsg("")
    SetLoading(true)

    try {
      await memberAuth.create(Email, Password).then(() => {
        memberAuth.login(Email, Password)
      })
    } catch (e) {
      const error = e as { message?: string } | undefined
      SetErrorMsg(error?.message ?? "Something went wrong. Try again")
      SetLoading(false)
    }
  }

  async function loginWithGmail() {
    SetErrorMsg("")
    SetLoading(true)

    try {
      await memberAuth.loginGmail()
    } catch (e) {
      const error = e as { message?: string } | undefined
      if (error?.message?.includes("popup-closed-by-user")) {
        return SetLoading(false)
      }
      SetErrorMsg(error?.message ?? "Something went wrong. Try again")
      SetLoading(false)
    }
  }

  return (
    <AuthFormWrapper loading={Loading}>
      {!!ErrorMsg && <ErrorAlert msg={ErrorMsg} />}
      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <input
          value={Email}
          onChange={(e) => SetEmail(e.target.value)}
          placeholder="email"
        />
        <input
          type="password"
          value={Password}
          onChange={(e) => SetPassword(e.target.value)}
          placeholder="password"
        />
        <input
          type="password"
          value={PasswordConfirmation}
          onChange={(e) => SetPasswordConfirmation(e.target.value)}
          placeholder="confirm password"
        />
        <button type="submit" onClick={signup} disabled={Loading}>
          <b>sign up</b>
        </button>
      </div>

      <button
        type="submit"
        onClick={loginWithGmail}
        disabled={Loading}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 32,
          padding: 1,
          gap: 4,
        }}
      >
        <GoogleG />
        <p style={{ marginTop: 2 }}>
          <b>Signup with Google</b>
        </p>
      </button>

      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        <button
          type="button"
          style={{
            width: "100%",
            color: "gray",
          }}
          onClick={() => navigate("/login")}
          disabled={Loading}
        >
          login
        </button>
        <button
          type="button"
          style={{
            width: "100%",
            color: "gray",
          }}
          onClick={() => navigate("/reset")}
          disabled={Loading}
        >
          reset password
        </button>
      </div>
    </AuthFormWrapper>
  )
}
