import { Centered } from "./centered"
import { LoadingOverlay } from "./loading-overlay"

export const AuthFormWrapper: React.FC<{
  loading: boolean
  header?: string
  children: React.ReactNode
}> = ({ loading, children, header }) => {
  return (
    <Centered>
      <LoadingOverlay loading={loading}>
        <>
          {header && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <h3>{header}</h3>
            </div>
          )}
          <form
            style={{
              display: "inline-flex",
              flexDirection: "column",
              width: 300,
              gap: 12,
            }}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            {children}
          </form>
        </>
      </LoadingOverlay>
    </Centered>
  )
}
