import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { ApolloProvider } from "@apollo/client"
import { memberAuth } from "../auth"
import { config } from "../config"

const { serverUrl, isDev } = config()

const httpLink = createHttpLink({
  uri: `${serverUrl}/graphql`,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    Sentry.captureException(networkError, {
      tags: {
        source: "gql_client",
        type: "networkError",
      },
    })
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((gqlError) => {
      Sentry.captureException(gqlError, {
        tags: {
          source: "gql_client",
          type: "gqlError",
        },
      })
    })
  }
})

const authLink = setContext(async (_, { headers }) => {
  const token = await memberAuth.token()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

export const apolloClient = new ApolloClient({
  link,
  name: "server",
  version: "0.0.0",
  connectToDevTools: isDev,
  credentials: "include",
  // Pagination:
  // https://www.apollographql.com/docs/react/pagination/core-api
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // Must make new merge
          // field for each list:
          // grandArchiveVariants: {
          //   // for now, we just clear the cache
          //   // when the InventoryContainer unmounts:
          //   keyArgs: false,
          //   merge(existing, incoming) {
          //     return {
          //       ...(existing ?? {}),
          //       ...(incoming ?? {}),
          //       items: [...(existing?.items ?? []), ...(incoming.items ?? [])],
          //     }
          //   },
          // },
        },
      },
    },
  }),
})

export const ApiProvider: React.FC<{
  children: Children
}> = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
