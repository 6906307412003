import styles from "./splash.module.scss"
import { Outlet, useNavigate } from "react-router-dom"
import { Centered } from "../shared/centered"

export const Brand = () => {
  return (
    <h1>
      <span>topdex</span>
      <span className={styles.tld}>.io</span>
    </h1>
  )
}

export const CTA = () => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
      }}
    >
      <button
        style={{
          width: "100%",
        }}
        onClick={() => navigate("/signup")}
      >
        signup
      </button>
      <button
        style={{
          width: "100%",
          color: "black",
        }}
        onClick={() => navigate("/login")}
      >
        login
      </button>
    </div>
  )
}

export const Splash: React.FC = () => {
  return (
    <Centered>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Brand />
          <h4 style={{ marginBottom: 10, color: "gray" }}>
            strategy unleashed
          </h4>
        </div>

        <Outlet />
      </div>
    </Centered>
  )
}
