const env = process.env.NODE_ENV
const isDev = env === "development"

export function config() {
  return {
    env,
    isDev,
    firebase: {
      apiKey: "AIzaSyAV3mstrd2fFqePNw9RKvCP3d-M5U2_dFk",
      authDomain: "deckbuilder-io.firebaseapp.com",
      projectId: "deckbuilder-io",
      storageBucket: "deckbuilder-io.appspot.com",
      messagingSenderId: "129183493208",
      appId: "1:129183493208:web:13c7beb00116adb677ee2d",
      measurementId: "G-L484JMLEY4",
    },
    serverUrl: isDev
      ? "http://localhost:4000"
      : "https://staging-builder-ed4f4d43f32d.herokuapp.com",
    sentry_dsn:
      "https://69a7e1977c53f04a5b7b6d1e70d50df7@o4506995512246272.ingest.us.sentry.io/4506995515129856",
  }
}
