import TextField from "@mui/material/TextField"
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined"
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"
import InputAdornment from "@mui/material/InputAdornment"
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import { AppLink } from "../shared/applink"
import styles from "./home.module.scss"
import { useState } from "react"
import cs from "clsx"

const HeroTitle = () => {
  return (
    <div className={styles.heroTitle}>
      <h2 className={styles.title}>
        topdex
        <WidgetsOutlinedIcon className={styles.logo} />
      </h2>
      <h4 className={styles.subtitle}>unlock the potential in any game</h4>
    </div>
  )
}

const HeroSearch = () => {
  const [search, setSearch] = useState("")
  const [entity, setEntity] = useState<"decks" | "members">("decks")

  return (
    <div className={styles.heroSearch}>
      <div className={styles.searchTabs}>
        <p className={styles.searchLabel}>search:</p>
        <button
          onClick={() => setEntity("decks")}
          className={cs(styles.tab, {
            [styles.selected]: entity === "decks",
          })}
        >
          decks
        </button>
        <button
          onClick={() => setEntity("members")}
          className={cs(styles.tab, {
            [styles.selected]: entity === "members",
          })}
        >
          members
        </button>
        <AppLink to="/main/decks">
          advanced
          <ArrowRightAltOutlinedIcon fontSize="medium" />
        </AppLink>
      </div>
      <TextField
        id="hero-search"
        value={search}
        size="small"
        className={styles.searchText}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={`search ${entity}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: search.length ? (
            <IconButton size="small" onClick={() => setSearch("")}>
              <ClearOutlinedIcon fontSize="small" />
            </IconButton>
          ) : undefined,
        }}
      />
    </div>
  )
}

export const Home = () => {
  return (
    <div className={styles.container}>
      <HeroTitle />
      <HeroSearch />
    </div>
  )
}
