import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom"
import { ReactElement, useEffect, useState } from "react"
import { Centered } from "./shared/centered"
import { memberAuth, useMemberAuth } from "./auth"
import { CTA, Splash } from "./pages/splash"
import { Signup } from "./pages/signup"
import { Login } from "./pages/login"
import { Reset } from "./pages/reset"
import { Main } from "./shared/main"
import { Home } from "./pages/home"

const homePath = "/main"

const LandingRedirect: React.FC<{
  type: "login" | "entry"
  children: ReactElement<any, any> | null
}> = ({ children, type }) => {
  const navigate = useNavigate()
  const { loading, member } = useMemberAuth()

  useEffect(() => {
    // wait for firebase to fetch data
    // before we decide on actions:
    if (loading) {
      return
    }

    // once firebase has finished loading,
    // we decide where and if to redirect:
    if (member) {
      if (type === "entry") {
        navigate("/main", {
          replace: true,
        })
      }
    } else {
      if (type === "login") {
        navigate("/", {
          replace: true,
        })
      }
    }
  }, [member, loading])

  if (loading) {
    return (
      <Centered>
        <p>loading...</p>
      </Centered>
    )
  }

  return children
}

const NoMatchRedirect = () => {
  const navigate = useNavigate()
  const [Count, SetCount] = useState(0)

  const limit = 5
  const diff = limit - Count

  useEffect(() => {
    const t = setInterval(() => {
      SetCount((C) => C + 1)
    }, 1000)
    return () => {
      clearInterval(t)
    }
  }, [])

  useEffect(() => {
    if (diff === 0) {
      navigate("/login")
    }
  }, [Count])

  if (diff == 0) {
    return null
  }

  return (
    <Centered>
      <p>
        This url no longer seems to work... <br /> We'll redirect you back home
        in {diff} secs
      </p>
    </Centered>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LandingRedirect type="entry">
        <Splash />
      </LandingRedirect>
    ),
    errorElement: <NoMatchRedirect />,
    children: [
      {
        path: "/",
        element: <CTA />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/reset",
        element: <Reset />,
      },
    ],
  },
  {
    path: "/main",
    element: (
      <LandingRedirect type="login">
        <Main />
      </LandingRedirect>
    ),
    children: [
      {
        path: "/main",
        element: <Home />,
      },
      {
        path: "/main/about",
        element: <div>about</div>,
      },
      {
        path: "/main/decks",
        element: <div>deck-list</div>,
        children: [
          {
            path: "/main/decks/:deckId",
            element: <div>single deck</div>,
          },
        ],
      },
      {
        path: "/main/collection",
        element: <div>sets-list</div>,
        children: [
          {
            path: "/main/collection/:collectionId",
            element: <div>single set</div>,
          },
        ],
      },
      {
        path: "/main/members",
        element: <div>member-list</div>,
        children: [
          {
            path: "/main/members/:memberId",
            element: <div>single member</div>,
          },
        ],
      },
    ],
  },
  {
    path: "/sandbox",
    element: (
      <div
        style={{
          background: "red",
          height: "100%",
        }}
      ></div>
    ),
  },
])

export const Router = () => <RouterProvider router={router} />
