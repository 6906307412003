import { Brand } from "../pages/splash"
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone"
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone"
import { useLocation, useNavigate } from "react-router-dom"
import { useMyDataQuery } from "../../generated/graphql-codegen"
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import styles from "./appbar.module.scss"
import cs from "clsx"

const CreateBtn = () => {
  const navigate = useNavigate()
  return (
    <Tooltip title="create new deck" placement="bottom">
      <IconButton
        onClick={() => navigate(`?modal=create-deck`)}
        aria-label="create new deck"
        size="small"
      >
        <AddCircleTwoToneIcon className={styles.createFab} fontSize="medium" />
      </IconButton>
    </Tooltip>
  )
}
const ProfileBtn = () => {
  return (
    <Tooltip title="manage profile" placement="bottom">
      <IconButton aria-label="profile" size="small">
        <AccountCircleTwoToneIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  )
}
const RecentBtn = () => {
  return (
    <Tooltip title="recent decks" placement="bottom">
      <IconButton aria-label="recent decks" size="small">
        <HistoryOutlinedIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  )
}

export const Appbar = () => {
  const { data, loading, error } = useMyDataQuery()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <nav className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.brand} onClick={() => navigate("/main")}>
          <Brand />
        </div>
        <div
          onClick={() => navigate("/main")}
          className={cs(styles.tab, {
            [styles.selected]: location.pathname === "/main",
          })}
        >
          home
        </div>
        <div
          onClick={() => navigate("/main/about")}
          className={cs(styles.tab, {
            [styles.selected]: location.pathname.includes("/about"),
          })}
        >
          about
        </div>
        <div className={cs(styles.divider)} />
        <div
          onClick={() => navigate("/main/decks")}
          className={cs(styles.tab, {
            [styles.selected]: location.pathname.includes("/decks"),
          })}
        >
          decks
        </div>
        <div
          onClick={() => navigate("/main/collection")}
          className={cs(styles.tab, {
            [styles.selected]: location.pathname.includes("/collection"),
          })}
        >
          collection
        </div>
      </div>
      <div className={styles.rightSide}>
        <CreateBtn />
        <RecentBtn />
        <ProfileBtn />
      </div>
    </nav>
  )
}
